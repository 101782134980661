import Popper from 'popper.js'
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

export default {
    name: 'CustomSelect',
    props: {
        options: {
          type: Array,
          required: true
        },
        tabindex: {
          type: Number,
          required: false,
          default: 0
        },
        value: {
          // type: [Object, Array, String, Number, Boolean],
          // default: undefined
        },
        label: String | null,
        isMultiple: {
          type: Boolean,
          default: false,
          required: false
        },
        required: {
          type: Boolean,
          default: false,
          required: false
        }
      },
      data() {
        return {
          selectedOption: null,
          selectedOptions: [],
          open: false,
          value_field_name: 'value',
          display_field_name: 'text',
          direction: 'down'
        }
      },
      mounted() {
        if (this.isMultiple) {
          this.options.forEach(x => {
            if (this.value.includes(x.value)) {
              this.selectedOptions.push(x)
            }
          })
        } else {
          this.selectedOption = this.options.find(x => x.value === this.value)
        }
        this.$nextTick(() => {
          new Popper(this.$refs['customizeSelectSelectedItem'], this.$refs['dropdown'], {placement: 'bottom-start'})
        })
      },
      watch: {
        value: function(v) {
          if(!v) {
            if (this.isMultiple) {
              this.selectedOptions = []
            } else {
              this.selectedOption = null
            }
          }
        }
      },
      methods: {
        onSelect(option) {
          if (this.isMultiple) {
            let _index = this.selectedOptions.findIndex(x => x.value === option.value)
            if(_index === -1) {
              this.selectedOptions.push(option)
            } else {
              this.selectedOptions.splice(_index,1)
            }
            this.$emit('input', this.selectedOptions.map(x => x.value))
          } else {
            this.selectedOption = option
            this.open = false
            this.$emit('input', option.value)
          }
        },
        onClickSelected() {
          // let _screenHeight = this.$root.$el.clientHeight
          // let _offsetTop = this.$el.offsetTop
          // let _parentOffsetTop = this.$el.offsetParent.offsetTop
          // let _clientHeight = this.$el.clientHeight
          // let _parentClientHeight = this.$el.offsetParent.clientHeight
          // if ((_screenHeight - (_clientHeight+_offsetTop)) < 210 || (_screenHeight - (_parentClientHeight+_parentOffsetTop)) < 210 ) {
          //   this.direction = 'up'
          // }
          this.open = !this.open
        },
        checkSelected(option) {
          if (this.isMultiple && this.selectedOptions) {
            return this.selectedOptions.find(x => x.value === option.value) ? true : false
          }
        }
      }
}