

export default {
    props: {
        docId: {type: Number, default: null},
        isSupplier: {type: Boolean, default: false},
        isProvider: {type: Boolean, default: false},
        isNotisphere: {type: Boolean, default: false},
        showView: {type: Boolean, default: false},
    },
    data() {
        return {
            url: require('@/assets/img/noti_lite_logo.png'),
            item: null
        }
    },
    computed: {
        fileType(){
            if (this.item.type && this.item.type.split('/')[0] == 'image') {
                return 'image'
            } else 
            if (this.item.type && this.item.type.split('/')[0] == 'application' && this.item.type.split('/')[1] == 'pdf') {
                return 'pdf'
            } else {
                return null
            }
        }
    },
    methods:{
        forceRerenderView() {
            this.getDocumentById().then(()=>{                
                this.$refs.modal.show()
            })
        },
        download() {
            if (this.item.signed_url) {
                this.openExternalLink(this.item.signed_url)
            } else {
                this._showToast('Nothing to download', {varient: 'danger'})
            }
        },
        async forceRerenderDownload() {      
            await this.getDocumentById()
            this.download()
        },
        // getDocumentById() {
        //     return new Promise ((resolve, reject) => {
        //         if(this.isProvider == true) {
        //             pro_RecallServices.getDocumentById(this.docId).then(resp => {
        //                 if (!resp.error) {
        //                     this.item = resp.data.d
        //                     resolve()
        //                 } else {
        //                     reject()
        //                 }
        //             })
        //         }
        //         if(this.isSupplier == true) {
        //             sup_RecallServices.getDocumentById(this.docId).then(resp => {
        //                 if (!resp.error) {
        //                     this.item = resp.data.d
        //                     resolve()
        //                 } else {
        //                     reject()
        //                 }
        //             })
        //         }
        //         if(this.isNotisphere == true) {
        //             noti_RecallServices.getDocumentById(this.docId).then(resp => {
        //                 if (!resp.error) {
        //                     this.item = resp.data.d
        //                     resolve()
        //                 } else {
        //                     reject()
        //                 }
        //             })
        //         }
        //     })
        // }
    }
}