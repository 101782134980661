import Datepicker from 'vuejs-datepicker'

export default {
    props: {
        value: {},
        label: { type: String, default: ''},
        clearButton: { type: Boolean, default: false },
        rules: { type: String, default: ''},
        validatorName: { type: String, default: ''},
        disabled: { type: Boolean, default: false},
        hideDetails: { type: Boolean, default: false}
    },
    components: {
        Datepicker
    },
    data(){
        return {
            fsWidth: 0,
            floatLabel: false,
            isFocus: false,
            // state : {
            //     date: new Date(2021, 4,  16)
            //   }
            state : {
                disabledDates: {
                  //to: new Date(2021, 5, 20), // Disable all dates up to specific date
                  from: new Date(), // Disable all dates after specific date
                  //days: [6, 0], // Disable Saturday's and Sunday's
                  //daysOfMonth: [29, 30, 31], // Disable 29th, 30th and 31st of each month
                //   dates: [ // Disable an array of dates
                //     new Date(2016, 9, 16),
                //     new Date(2016, 9, 17),
                //     new Date(2016, 9, 18)
                //   ],
                //   ranges: [{ // Disable dates in given ranges (exclusive).
                //     from: new Date(2016, 11, 25),
                //     to: new Date(2016, 11, 30)
                //   }, {
                //     from: new Date(2017, 1, 12),
                //     to: new Date(2017, 2, 25)
                //   }],
                  // a custom function that returns true if the date is disabled
                  // this can be used for wiring you own logic to disable a date if none
                  // of the above conditions serve your purpose
                  // this function should accept a date and return true if is disabled
                //   customPredictor: function(date) {
                //     // disables the date if it is a multiple of 5
                //     if(date.getDate() % 5 == 0){
                //       return true
                //     }
                //   }
                }
              }
        }
    },
    mounted(){
        this.checkFloatLabel(this._value)
    },
    computed: {
        disabledValidate() {
            return !this.rules || this.rules == '' ? true : false
        },
        _value: {
            get(){
                return this.value
            },
            set(val){
                this.$emit('input', val)
            }
        },
        lblWidth: {
            get() {
                if (this.floatLabel)
                    return this.fsWidth + 'px'
                return 0
            }
        }
    },
    watch: {
        _value(val){
            this.checkFloatLabel(val)
        }
    },
    methods: {     
        onUpdate() {
            this.$emit('update')
        },

        clickLabel() {
            this.$refs.theInput.focus()
        },

        fieldFocus() {
            this.addFloatLabel()
            this.addFocus()
        },
        fieldBlur() {

            if(!this._value){
                this.removeFloatLabel()
            }
            this.removeFocus()
        },
        checkFloatLabel(val) {
            if(!val && !this.isFocus){
                this.removeFloatLabel()
            } else {
                this.addFloatLabel()
            }
        },
        addFloatLabel() {
            if(this.$refs.wrapper.$el.textContent != "" && this.$refs.wrapper.$el.textContent != "Field required" )
            {
            //this.$refs.wrapper.$el.classList.add('float-label')
            // setTimeout(()=> {
            //     this.fsWidth = this.$refs.lbl.clientWidth
            // }, 250)
            //this.floatLabel = true
        }
        },
        removeFloatLabel() {
            this.$refs.wrapper.$el.classList.remove('float-label')
            this.floatLabel = false
        },
        addFocus() {
            this.$refs.wrapper.$el.classList.add('focus')
            this.isFocus = true
        },
        removeFocus() {
            this.$refs.wrapper.$el.classList.remove('focus')
            this.isFocus = false
        }
    }
}