import { AUTH_ACTIONS } from '../../../store/actions'
import { paths } from '../../../router/paths'
import { getDefaultRoute } from '../../../router'
import ContactSupportModal from './ContactSupportModal'
export default {
    components: {
        ContactSupportModal
    },
    data() {
        return {
            isSidebarOpen: true,
            btnActionType : null,
            items: [
                {
                    to: paths.recalls.path,
                    text: 'Recalls List',
                    icon: 'fas fa-question-circle',
                    isAllowed: true
                },
            ],
            supplierModalKey: 0,
        }
    },
    created(){
        console.clear();
    },
    computed: {
        user() {
            //return this.$store.state.user
            return localStorage.getItem('storedData')
        },
        userPhoto() {
            return _.isObject(this.$store.state.user) && this.$store.state.user.photo ? this.$store.state.user.photo : null
        },
        org() {
            return this.$store.state.organization
        },
        orgPhoto() {
            return _.isObject(this.$store.state.organization) && this.$store.state.organization.profile_photo ? this.$store.state.organization.profile_photo : null
        },
        crumbs: function() {
            let pathArray = this.$route.path.split('/')
            
            pathArray.shift()
            var matched = this.$route.matched
            var defaultRoute = getDefaultRoute()
            var breadcrumbs = []
            if(matched[0] && matched[0].path == defaultRoute) {
                breadcrumbs.push({
                    to: null,
                    text: 'Home'
                })
            } else {
                breadcrumbs.push({
                    to: defaultRoute,
                    text: 'Home'
                })
                pathArray.forEach((el, idx) => {
                    var fullPath = ''
                    for (let j = 0; j <= idx; j++) {
                        fullPath += '/' + pathArray[j]
                    }
                    for (const prop in paths) {
                        if(paths[prop].path.replace(/\/:id$/, '') == fullPath) {
                            breadcrumbs.push({
                                to: fullPath,
                                text: paths[prop].meta.breadCrumb
                            })
                        }
                    }
                })
            }
            var cnt = 0;
            
            breadcrumbs.forEach((el, index) => {
                ////debugger
            if(el.text == 'Home')
            {
                cnt++;
            }
            if(cnt > 1)
            {
                breadcrumbs.splice(index, 1)   
            }
            })
            breadcrumbs[breadcrumbs.length - 1].to = null
            return breadcrumbs
        }
    },

    created() {
        window.addEventListener('resize', this.screenResizeHandler)
    },

    destroyed() {
        window.removeEventListener('resize', this.screenResizeHandler)
    },

    mounted() {
        this.screenResizeHandler()
    },

    watch: {
        isSidebarOpen(){
            if(this.btnActionType == 'mobile') {
                this.$refs.mainWrapper.classList.toggle('slide-nav')   
                this.$refs.sidebar.classList.toggle('opened')     
                //this.$refs.overlay.classList.toggle('show')                
            } else {
                this.$refs.mainWrapper.classList.toggle('mini-sidebar')                    
            }
            this.btnActionType = null
        }
    },

    methods:{
        ContactSupportPopup() {
           console.clear();
            this.supplierModalKey += 1
            this.$nextTick(() => {
                this.$refs.supplierModal.forceRerenderInsert()
            })
        },
        overlayClick() {
            this.btnActionType = 'mobile'
            this.isSidebarOpen = !this.isSidebarOpen
        },
        sidebarToggle(type) {
            this.btnActionType = type
            this.isSidebarOpen = !this.isSidebarOpen
        },
        logout() {
            console.clear();
            
            this.$store.dispatch(AUTH_ACTIONS.LOGOUT)

            this.$router.push({name: 'Login'})
            localStorage.clear()
            console.clear();
        },
        screenResizeHandler() {
        },
        hoverOn() {
            this.$refs.mainWrapper.classList.add('expand-menu')
        },
        hoverOff() {
            this.$refs.mainWrapper.classList.remove('expand-menu')
        },
        QuickStartVideo() {
            this.openExternalLink('https://www.notisphere.com/er-quickstart');
        },
        QuickStartGuide() {
            this.openExternalLink('https://prod-er-data.s3.us-east-2.amazonaws.com/QuickStartGuide.pdf');
        },
        ContactSupport() {
            //this.$refs.mainWrapper.classList.remove('expand-menu')
        },
        openAccountMenu() {
            //debugger
            this.$refs.accountMenu.show()
            this.$refs.accountBtn.classList.add('show')
        },
        clickOutsideAccountMenu() {
            this.$refs.accountBtn.classList.remove('show')
        },
        openAccountMenuMobile() {
            this.$refs.accountMenuMobile.show()
        },
        goToOrganizationGeneralSetting() {
            this.$router.push({name: 'Account'})
        }
    }
}