
export default {
    props: {
        fileId: {type: String, default: null},
        showBtn: {type: Boolean, default: true},
        isSupplier: {type: Boolean, default: false},
        showView: {type: Boolean, default: false}
    },
    data() {
        return {
            url: require('@/assets/img/noti_lite_logo.png'),
            item: null
        }
    },
    computed: {
        fileType(){
            if (this.item.type && this.item.type.split('/')[0] == 'image') {
                return 'image'
            } else 
            if (this.item.type && this.item.type.split('/')[0] == 'application' && this.item.type.split('/')[1] == 'pdf') {
                return 'pdf'
            } else {
                return null
            }
        }
    },
    methods:{
        forceRerenderView() {
            this.getFileById().then(()=>{                
                this.$refs.modal.show()
            })
        },
        download() {
            if (this.item.signed_url) {
                this.openExternalLink(this.item.signed_url)
            } else {
                this._showToast('Nothing to download', {varient: 'danger'})
            }
        },
        async forceRerenderDownload() {      
            await this.getFileById()
            this.download()
        },
        // getFileById() {
        //     return new Promise ((resolve, reject) => {
        //         if (this.isSupplier == true) {
        //             sup_recallServices.getFileById(this.fileId).then(resp => {
        //                 if (!resp.error) {
        //                     this.item = resp.data.d
        //                     resolve()
        //                 } else {
        //                     reject()
        //                 }
        //             })
        //         } else {
        //             pro_recallServices.getFileById(this.fileId).then(resp => {
        //                 if (!resp.error) {
        //                     this.item = resp.data.d
        //                     resolve()
        //                 } else {
        //                     reject()
        //                 }
        //             })
        //         }                
        //     })
        // }
    }
}