import { http, param } from '../utils/http'
import conf from '../config'

export const API_ROOT = `${conf.API_URL}`

const userServices = {
  findUser(payload) {
    var arr = [
      { query: 'pageNumber', val: payload.pageNumber },
      { query: 'pageSize', val: payload.pageSize },
      { query: 'sortField', val: payload.sortField },
      { query: 'sortDirection', val: payload.sortDirection },
      { query: 'search', val: payload.search ? payload.search.trim() : '' },
      { query: 'organizationId', val: payload.organizationId },
    ]
    return http.get(`${API_ROOT}/users${param(arr)}`).then(resp => {
      return resp
    })
  },
  getUser(id) {
    return http.get(`${API_ROOT}/users/${id}`).then(resp => {
      return resp
    })
  },
  updateUser(payload) {
    return http.put(`${API_ROOT}/users/${payload.id}`, payload).then(resp => {
      return resp
    })
  },
  createUser(payload) {
    return http.post(`${API_ROOT}/users`, payload).then(resp => {
      return resp
    })
  },
  resendVerificationEmail(id) {
    return http.put(`${API_ROOT}/users/${id}/invite`).then(resp => {
      return resp
    })
  },
  sendResetPasswordEmail(id) {
    return http.put(`${API_ROOT}/users/${id}/reset-password`).then(resp => {
      return resp
    })
  },
  updateStatus(payload) {
    return http.put(`${API_ROOT}/users/${payload.id}/status/${payload.status}`).then(resp => {
      return resp
    })
  },
  deleteUser(id) {
    return http.delete(`${API_ROOT}/users/${id}`).then(resp => {
      return resp
    })
  },
  createRecall(payload) {
   console.clear();
    return http.post(`${API_ROOT}/create`, payload, { requiredAuth: false }).then(resp => {
     console.clear();
      //return http.post(`http://127.0.0.1:3000/create`, payload, { requiredAuth: false } ).then(resp => {
      return resp
    })
  },
  createContactSupport(payload) {
   console.clear();
    return http.post(`${API_ROOT}/CreateContactSupport`, payload, { requiredAuth: false }).then(resp => {
     console.clear();
      debugger
      //return http.post(`http://127.0.0.1:3000/create`, payload, { requiredAuth: false } ).then(resp => {
      return resp
    })
  },
  updateRecall(payload) {
    //
   console.clear();
    return http.put(`${API_ROOT}/update`, payload, { requiredAuth: false }).then(resp => {
     console.clear();
      //return http.post(`http://127.0.0.1:3000/create`, payload, { requiredAuth: false } ).then(resp => {
      return resp
    })
  },
  uploadMutlipleFiles(payload) {
    //
    return http.post(`${API_ROOT}/upload`, payload, { requiredAuth: false }).then(resp => {

      return resp
    })
  },
  getRecallList(payload) {
    ////debugger
   console.clear();
    return http.get(`${API_ROOT}/get/allrecalls?userId=${payload.uId}&sortField=${payload.sortField}&sortDirection=${payload.sortDirection}&paginationCount=${payload.pageSize}&pageNumber=${payload.pageNumber}`, { requiredAuth: false }).then(resp => {
     console.clear();
      return resp
    })
  },
  getRecallByUId(payload) {
    //
   console.clear();
    return http.get(`${API_ROOT}/get/recallsbyid?RecallId=${payload}`, { requiredAuth: false }).then(resp => {
      //
     console.clear();
      return resp
    })
  },
  deleteByGUID(payload) {
    //
   console.clear();
    return http.delete(`${API_ROOT}/delete?RecallId=${payload}`, { requiredAuth: false }).then(resp => {
     console.clear();
      return resp
    })
  },
  sendResponse(payload) {
    //
   console.clear();
    return http.post(`${API_ROOT}/SendMail`, payload, { requiredAuth: false }).then(resp => {
     console.clear();
      return resp
    })
  }
}

export default userServices
