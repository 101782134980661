import VueDropzone from 'vue2-dropzone'
import userServices from '../../../../services/user'
import S3 from 'aws-s3'
import { uuid } from 'vue-uuid';
import conf from '../../../../config'
export const S3Url = `${conf.S3Url}`
export const S3BUCKET = `${conf.S3BUCKET}`
export default {
    components: {
        VueDropzone,
    },
    sprops: ['infoData', 'recallMetaData', 'recallDisabled'],
    data() {
        return {
            uuid: uuid.v1(),
            item: {
                description: null,
                emailId: null,
                userId: null,
                createdBy: null,
                description: null,
                organization:null,
                userName:null,
            },
            formType: 'INSERT',
            recallId: null,
            errorList: [],
            RecallsList: [],
            filesUpload: [],
            filespaths: [],
            s3upload: [],
            IsUpdated:false,
            filesUploadUpdate: [],
            filesUploadOriginal: [],
            cnt: 0,
            uploadcnt: 0,
            thumbnailIndex: -1,
            updateRecallId: '',
        }
    },
    mounted() {
        this.info = _.cloneDeep(this.infoData)
        localStorage.removeItem('FileContent')
    },
    created() {
        console.clear();
      },
      watch: {
            // 'item.notificationDate'(val){
            //    console.clear();
            // }
        },
    computed: {
        form(){
            if(this.formType == 'INSERT'){
                return {
                    title: 'Contact Support',
                    showAdminCreation: true,
                    showStatusSwitcher: false,
                    class:"card-title"
                }
            } 
        },
        S3Url()        
        {
            return S3Url
            //return 'https://prod-er-data.s3.us-east-2.amazonaws.com'
            //return 'https://qa-er-data.s3.us-east-2.amazonaws.com'
        },
        FileNameByGUID()
         {  
            return  this.$uuid.v4().toString().substr(29)
         },
        GetUserId(){
            return localStorage.getItem('userId');
         },
         GetUserDetails(){
            return JSON.parse(localStorage.getItem("userD"));
         }
    },
    methods:{
        removeDocument(index) {
            if (this.formType == "INSERT") {
                this.filesUpload.splice(index, 1)
                this.thumbnailIndex = this.filesUpload.findIndex(x => x.is_thumbnail === true)
                var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                storedNames.splice(index, 1)
                localStorage.removeItem('FileContent')
                localStorage.setItem("FileContent", JSON.stringify(storedNames));
            }
           console.clear();
        },
        async handleFilesUpload() {
           console.clear();
            const config = {
                //bucketName: 'qa-er-data',
                //bucketName: 'prod-er-data',
                bucketName: S3BUCKET,
                dirName: this.$uuid.v4(),
                region:'us-east-2',
                accessKeyId:'AKIA3PVXGLRQ6AHCKHWW',
                secretAccessKey:'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',
                s3Url: this.S3Url,
            }
            const S3Client = new S3(config);
            let uploadedFiles = this.$refs.files.files
            this.uploadcnt = 0;
            this.cnt = 0;
            this.uploadcnt = uploadedFiles.length
            var extList = ["jpeg", "jpg", "png", "tiff"]
            //debugger
            this._loadingStart()
            for ( let i = 0; i < uploadedFiles.length; i++ ) {
                let fileNameUpload = uploadedFiles[i].name.split(".")[0];
                this.s3upload.push({
                    name: fileNameUpload,
                })
                
                if(extList.includes(uploadedFiles[i].name.split('.')[1]))
                {
                    //debugger
                    S3Client.uploadFile(uploadedFiles[i],fileNameUpload,uploadedFiles[i].name.split('.')[1]).then(data=>
                        {
                            this.IsUpdated = true;
                            this.cnt++;
                            var userD = [];
                            var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                            if (storedNames == null) {
                                userD[0] = data.location
                            }
                            else    
                            {
                             for ( let i = 0; i < storedNames.length; i++ ) {
                                    userD[i] = storedNames[i];
                                }   
                                userD[storedNames.length] = data.location;
                            }
                            localStorage.setItem("FileContent", JSON.stringify(userD));    
                            if(this.cnt == this.uploadcnt) {
                                this._loadingEnd()
                            }
                        }).catch(err=>{
                            //debugger
                        })
                }
                else 
                {
                    this._loadingEnd()
                    this._showToast('Only jpeg, jpg, png, tif files are allowed', { variant: 'danger', title: 'Error' })
                }
            
            }
            
              for ( let i = 0; i < uploadedFiles.length; i++ ) {
                
                  if (this.formType == "INSERT") {
                    if(extList.includes(uploadedFiles[i].name.split('.')[1]))
                    {
                    this.filesUpload.push({
                        name: uploadedFiles[i].name,
                        size: uploadedFiles[i].size,
                        type: uploadedFiles[i].type,
                        file: uploadedFiles[i],
                    })
                }
                  }
            }
            this.$refs.files.value = ''
           console.clear();
        },
        handleChangeThumbnail(val) {
            this.thumbnailIndex = val
            this.filesUpload.forEach((x, index) => {
                if(index === val) {
                    x.is_thumbnail = true
                } else {
                    x.is_thumbnail = false
                }
            })
           console.clear();
        },
        forceRerenderInsert() {
            this.formType = 'INSERT'
            this.$refs.modal.show()
           console.clear();
        },
        sendEmail(fileList) {
           console.clear();
            //this.beforeupdateRecall()
           console.clear();
            //var toEmail = this.GetUserDetails[3]
            var fileP = [];
            fileList.forEach((x, i) => {
                //debugger
                var fname = x.FileName != undefined ? x.FileName : x.fileName;
                var fpath = x.FilePath!= undefined ? x.FilePath : x.filePath;
                if (fname != "") {
                    fileP.push({
                        "FileName": fname,
                        "FilePath": fpath
                    })
                }
            });
//debugger
var attachstring = fileP.length > 0 ? "Please find screenshot attached below." : "";
var myvar ='<div class="x_1362993703col-md-12" style="/* margin-bottom :  30px; */"><br><span class="x_1362993703mb-0" style="color :  black; "><span>Dear </span> <span style="font-weight: 700;">NotiSphere ER Support,</span></span><br>'+
'<br><span class="x_1362993703mb-0" style="color :  black; "><span>Please see details below :</span></span><br>'+
'<br><span class="x_1362993703mb-0" style="color :  black; "><span>Name : <span style="font-weight :  600; ">' + this.GetUserDetails[1] + " " + this.GetUserDetails[2] + '</span></span></span><br>'+
'<br><span class="x_1362993703mb-0" style="color :  black; "><span>Email : <span style="font-weight :  600; ">' + this.GetUserDetails[3] + '</span></span></span><br>'+
'<br><span class="x_1362993703mb-0" style="color :  black; "><span>Organization : <span style="font-weight :  600; ">' + this.item.organisation + '</span></span></span><br>'+
'<br><span class="x_1362993703mb-0" style="color :  black; "><span>Description : <span style="font-weight :  600; ">' + this.item.description + '</span></span></span><br>'+
'<br><span class="x_1362993703mb-0" style="color :  black; "><span>' + attachstring + '</span></span><br><br>'+
// '<span style="font-weight: 700;">Sincerely,</span><br>';
// '<span style="font-weight: 700;">' + this.GetUserDetails[1] + " " + this.GetUserDetails[2] + ',</span><br>';
// '<span style="font-weight: 700;">' + this.GetUserDetails[3] + ',</span><br>';
// '<span style="font-weight: 700;">' + this.GetUserDetails[4] + ',</span><br>';
// '<span style="font-weight: 700;">' + this.GetUserDetails[5] + ',</span><br>';
'</div>';
            var itemData =
            {
                "Subject": "NotiSphere ER Support",
                "FromEmail": "support@notisphererecalls.com",
                "ToEmail": "support@notisphere.com",
                //"ToEmail": "sagars@incubxperts.com",
                //"ToEmail": "pratikbh@incubxperts.com",
                "Body": myvar,
                "FileAttachment": fileP,
                "RecallId": '1',
            }
            //debugger
            userServices.sendResponse(itemData).then(resp => {
                this.$refs.modal.hide()
                this._showToast('Your support request has been sent.', { variant: 'success' })
               console.clear();
               console.clear();
            })
        },
        async createRecall(e) {
            ////debugger
           console.clear();
            const isValid = await this.$refs['observer'].validate()
            ////debugger
            if(isValid)
            {  
                    if (!this.filesUpload.length == 0) {
                    var fileP =[];
                    var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                    //debugger
                    this.filesUpload.forEach((x, i) => {
                                let s3U = this.S3Url + "/"
                                let path = '';
                                storedNames.forEach((y, i) => {
                                    var fn = x.name.split(".")[0]
                                    if(y.toString().includes(fn))
                                    {
                                        path = y
                                    }
                                    })
                                var fName = path.split(s3U)[1];
                                var nFname = fName.split('/')[1] != undefined ? fName.split('/')[1] : fName.split('/')[0];
                                ////debugger
                                fileP.push({
                                    "filePath": path,
                                    "fileName": nFname,
                                    "fileType": x.type,
                                    "fileSize": x.size
                                })
                            })
                            ////debugger
                                    var uId = this.GetUserId
                                    var itemData ={
                                        "Files": fileP,
                                        "SupportId": "",
                                        "emailId": this.GetUserDetails[3],
                                        "userName": this.GetUserDetails[1] + " " + this.GetUserDetails[2],
                                        "description": this.item.description,
                                        "organization": this.item.organisation,
                                        "userId": uId,
                                        "createdAt": "10/10/2020",
                                        }
                                    userServices.createContactSupport(itemData).then(resp=>{          
                                        if(resp.data.data){   
                                            this.sendEmail(fileP)
                                        }
                                    })
                                }
                    if (this.filesUpload.length == 0) {
                        var uId = this.GetUserId;
                        var fileP = [{
                            "filePath": "Empty",
                            "fileName": "",
                            "fileType": "",
                            "fileSize": ""
                        }]
                        var itemData ={
                                "Files": fileP,
                                "SupportId": "",
                                "emailId": this.GetUserDetails[3],
                                "userName": this.GetUserDetails[1] + " " + this.GetUserDetails[2],
                                "description": this.item.description,
                                "organization": this.item.organisation,
                                "userId": uId,
                                "createdAt": "10/10/2020",
                            }
                        userServices.createContactSupport(itemData).then(resp=>{          
                            ////debugger
                            if(resp.data.data){   
                                this.sendEmail(fileP)
                            }
                        })   
                    }
            }
           console.clear();
        }
    }
}