import generic from '../misc/generic'

export const USER_TYPE = {
  NOTI: 'NOTISPHERE',
  PROV: 'PROVIDER',
  SUPP: 'SUPPLIER'
}

export const THRESHOLD_COLOR_STATUS = [
  { value: 'RED', text: 'danger', color: '#dc3545'},
  { value: 'GREEN', text: 'success', color: '#28a745'},
  { value: 'YELLOW', text: 'warning', color: '#ffc107'}
]

export const TYPICAL_STATUS = [
  { value: 'ACTIVE', text: 'Active', color: '#28a745' },
  { value: 'INACTIVE', text: 'Inactive', color: '#9e9e9e' },
  { value: 'DELETED', text: 'Deleted', color: '#dc3545' }
]

export const USER_STATUS = [
  { value: 'VERIFIED', text: 'Verified', color: '#28a745' },
  { value: 'DEACTIVATED', text: 'Deactivated', color: '#dc3545' },
  { value: 'LOCKED', text: 'Locked', color: '#dc3545' },
  { value: 'PENDING', text: 'Pending', color: '#ffc107' }
]

export const ORGANIZATION_STATUS = [
  { value: 'VERIFIED', text: 'Verified', color: '#28a745' },
  { value: 'DEACTIVATED', text: 'Deactivated', color: '#dc3545' },
  { value: 'PENDING', text: 'Pending', color: '#ffc107' }
]

export const RECALL_STATUS = [
  { value: 'DRAFT', text: 'DRAFT', color: '#ffc107' },
  { value: 'SENT', text: 'SENT', color: '#28a745' },
  // { value: 'Send', text: 'SENT', color: '#28a745' },
  // { value: 'SENT', text: 'SENT', color: '#28a745' },
  { value: 'ERROR', text: 'ERROR', color: '#dc3545' }
]

export const RECALL_STATUS_PROVIDER = [
  { value: 'OPEN', text: 'In Progress', color: '#28a745' },
  { value: 'COMPLETED', text: 'Completed', color: '#007bff' }
]

export const RECALL_CLASSIFICATIONS = [
  {value: 'CLASSI', text: 'Class I'},
  {value: 'CLASSII', text: 'Class II'},
  {value: 'CLASSIII', text: 'Class III'},
  {value: 'NOT_YET_CLASSIFIED', text: 'Not Yet Classified'}
]

export const PRODUCT_FIELD_DATA_TYPE = [
  {value: 'Text', text: 'Text' },
  {value: 'Number', text: 'Number' },
  {value: 'Date', text: 'Date' },
  {value: 'ListSingle', text: 'List Single' },
  {value: 'ListMultiple', text: 'List Multiple' },
  {value: 'YesNo', text: 'Yes/No' },
  {value: 'TrueFalse', text: 'True/False' }
]

export const PRODUCT_FIELD_DATA_TYPE_OPTIONS_DATE = [
  {value: 'CURRENT_TIME', text: 'Current  Date/Time'},
  {value: 'NOT_APPLY', text: 'Blank'}
]

export const ACTION_TYPE_LIST = [
  {
      value: 'YesNo',
      text: 'Yes/No',
      icon: '<i class="fas fa-american-sign-language-interpreting"></i>'
  },
  {
      value: 'Text',
      text: 'Text',
      icon: '<i class="fas fa-align-right"></i>'
  },
  {
      value: 'Image',
      text: 'Image',
      icon: '<i class="far fa-images"></i>'
  },
  {
      value: 'Document',
      text: 'Document',
      icon: '<i class="far fa-file-alt"></i>'
  },
  {
      value: 'Checkbox',
      text: 'Checkbox',
      icon: '<i class="far fa-check-square"></i>'
  },
  {
      value: 'Numeric',
      text: 'Numeric',
      icon: '<i class="fas fa-sort-numeric-down"></i>'
  }
]

export const ENUM_PRODUCT_FIELD_DATA_TYPE = {
  TEXT: 'Text',
  NUMBER: 'Number',
  DATE: 'Date',
  LIST_SINGLE: 'ListSingle',
  LIST_MULTIPLE: 'ListMultiple',
  YES_NO: 'YesNo',
  TRUE_FALSE: 'TrueFalse'
}

export const ENUM_DATE_TYPE_DEFAULT_VALUE = {
  CURRENT_TIME: 'CURRENT_TIME',
  NOT_APPLY: 'NOT_APPLY'
}

export class Product {
  constructor(data) {
    this.id = data && data.id || null
    this.description = data && data.description || ''
    this.fields = data && data.fields || [],
    this.order = data && data.order || 1
  }
}

export const COLOR_CODES = [
  {
    id: 0, //red
    code: '#ef5350'
  },
  {
    id: 1, // purple
    code: '#ea80fc'
  },
  {
    id: 2, //blue
    code: '#42a5f5'
  },
  {
    id: 3, //teal
    code: '#26a69a'
  },
  {
    id: 4, //yelllow
    code: '#ffeb3b'
  },
  {
    id: 5, //orange
    code: '#ff9800'
  },
  {
    id: 6, //brown
    code: '#bcaaa4'
  },
  {
    id: 7, //grey
    code: '#9e9e9e'
  },
  {
    id: 8, //blue grey
    code: '#90a4ae'
  },
  {
    id: 9, //cyan
    code: '#26c6da'
  }
]

export const AVATAR_COLOR_CODES = [
  {
    id: 0,
    code: 'dark'
  },
  {
    id: 1,
    code: 'secondary'
  },
  {
    id: 2,
    code: 'success'
  },
  {
    id: 3,
    code: 'warning'
  },
  {
    id: 4,
    code: 'danger'
  },
  {
    id: 5,
    code: 'primary'
  }
]

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000'
