import _ from 'lodash'
import authService from '../services/auth'
import state from './state'
import { APP_MUTATIONS, AUTH_MUTATIONS, LANG_MUTATIONS } from './mutations'
import conf from '../config'
import { setToken, removeToken, getPermissions, getUserType, getOrgId } from '../misc/auth'
export const APP_ACTIONS = {
  SET_PREVIOUS_ROUTE: 'SET_PREVIOUS_ROUTE'
}

export const AUTH_ACTIONS = {
  LOGOUT: 'LOGOUT',
  LOGIN: 'LOGIN',
  LOGIN_LITE: 'LOGIN_LITE',
  GET_USER_PROFILE: 'GET_USER_PROFILE',
  GET_ORGANIZATION_INFO: 'GET_ORGANIZATION_INFO'
}

export const LANG_ACTIONS = {
  SET_LANG: 'SET_LANG'
}

const appActions = {
  [APP_ACTIONS.SET_PREVIOUS_ROUTE]: (context, data) => {
    if (data) {
      if (!context.state.authRoutes.includes(data.path) && !data.meta.auth) {
        context.commit(APP_MUTATIONS.SET_PREVIOUS_ROUTE, data)
      }
    }
  }
}

const authActions = {
  [AUTH_ACTIONS.LOGIN]: (context, payload = {}) => {
    const {
      grant_type,
      email,
      password,
      refresh_token,
      login_as,
      login_as_org_id
    } = _.isObject(payload) ? payload : {}
    context.commit(APP_MUTATIONS.AUTH_REQUEST)
    return authService.login(conf.CLIENT_ID, grant_type, email, password, refresh_token, login_as, login_as_org_id).then(resp => {
      if (resp.error) {
        context.commit(AUTH_MUTATIONS.AUTH_ERROR)
      } else {
        if (resp.data.d.login_as_required) {
          let obj = resp.data.d
          context.commit(AUTH_MUTATIONS.SELECT_TYPE_LOGIN, obj)
        } else {
          let token = resp.data.d
          context.commit(AUTH_MUTATIONS.AUTH_SUCCESS, token)
          context.dispatch(AUTH_ACTIONS.GET_USER_PROFILE)
        }
      }
      return resp
    }).catch(() => {
      context.commit(AUTH_MUTATIONS.AUTH_ERROR)
    })
  },
  [AUTH_ACTIONS.LOGIN_LITE]: (context, payload = {}) => {
    const {
      grant_type,
      email,
      password,
      refresh_token,
      login_as,
      login_as_org_id
    } = _.isObject(payload) ? payload : {}
    context.commit(APP_MUTATIONS.AUTH_REQUEST)
    let token = refresh_token
    context.commit(AUTH_MUTATIONS.AUTH_SUCCESS, token)
    //this.$router.push({name: 'Recalls'})
  },
  [AUTH_ACTIONS.GET_USER_PROFILE]: (context) => {
    if (state.user === '' && state.token) {
      // return authService.getUserProfile().then(resp => {
      //   if (!resp.error) {
      //     context.commit(AUTH_MUTATIONS.UPDATE_USER_PROFILE, resp.data.d)
      //     context.dispatch(AUTH_ACTIONS.GET_ORGANIZATION_INFO)
      //   }
      //   return resp
      // })
      var newData = [{
        email: "balajimasal.incubxperts@gmail.com",
fname: "Balaji",
full_name: "Balaji Masal",
id: 98,
lname: "Masal",
location: "pune",
org_id: 1,
org_name: "Notisphere",
phone: "9403248016",
photo: "",
role: "NotiSphere Admin",
status: "VERIFIED",
title: "Mr."
}];
      return newData 
      //return localStorage.getItem('storedData')
    }
  },
  [AUTH_ACTIONS.GET_ORGANIZATION_INFO]: (context) => {
    if (state.token) {
      return authService.getOrganizationInfo().then(resp => {
        if (!resp.error) {
          context.commit(AUTH_MUTATIONS.UPDATE_ORGANIZATION_INFO, resp.data.d)
        }
        return resp
      })
    }
  },
  [AUTH_ACTIONS.LOGOUT]: (context, currentRoute) => {
    // if (currentRoute && currentRoute.meta) {
    //   context.commit(APP_MUTATIONS.LASTED_ROUTE_NOT_AUTH, currentRoute)
    // }
    //context.commit(AUTH_MUTATIONS.AUTH_ERROR)
    // return authService.logout().then(resp => {
     
    // });
    removeToken()
  },
}

const langActions = {
  [LANG_ACTIONS.SET_LANG]: (context, payload) => {
    context.commit(LANG_MUTATIONS.SET_LANG, payload)
    localStorage.i18n_locale = payload
  }
}

export default {
  ...appActions,
  ...authActions,
  ...langActions 
}
