import http from '../utils/http'
import conf from '../config'
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
export const API_ROOT = `${conf.API_URL}`

const authService = {
  login (client_id, grant_type, email, password, refresh_token, login_as, login_as_org_id) {
    // return http.post(`${API_ROOT}/auth/token`, {
    //   client_id,
    //   grant_type,
    //   email,
    //   password: password,
    //   refresh_token: refresh_token,
    //   login_as: login_as,
    //   login_as_org_id: login_as_org_id
    // }).then(resp => {
    //   return resp
    // })
    
  },
  async signInUser(username, password) {
    try {
      const user = await Auth.signIn(username, password);
      if (user) {
        AmplifyEventBus.$emit('authState', 'signedIn');
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === 'PasswordResetRequiredException') {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === 'NotAuthorizedException') {
        // The error happens when the incorrect password is provided
      } else if (err.code === 'UserNotFoundException') {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        //console.log(err);
      }
    }
  },
 getUserData () {
     return Auth.currentSession();
  },
    logout () {
    return http.post(`${API_ROOT}/auth/logout`).then(resp => {
      return resp
    })
  },
  // getUserProfile () {
  //   // return http.get(`${API_ROOT}/me`).then(resp => {
  //   //   return resp
  //   // })
  //   var too = localStorage.getItem('storedData')
  //   //debugger
  //   return localStorage.getItem('storedData')
  // },
  updateProfile (payload) {
    // return http.put(`${API_ROOT}/me`, payload).then(resp => {
    //   return resp
    // })
    var too = localStorage.getItem('storedData')
    return localStorage.getItem('storedData')
  },
  changePassword (payload) {
    return http.put(`${API_ROOT}/me/change-password`, payload).then(resp => {
      return resp
    })
  },
  getOrganizationInfo () {
    return http.get(`${API_ROOT}/me/organization`).then(resp => {
      return resp
    })
  }, 
}

export default authService
