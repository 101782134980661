import Vue from 'vue'
import _ from 'lodash'

Vue.filter('time', function (value) {
    return value ? moment(value, 'YYYY-MM-DD HH:mm').format('HH:mm') : ''
})

Vue.filter('shortDate', function (time) {
    return time ? moment(time).format('MMM DD, YYYY') : ''
})

//Vue.filter('toLocal', function (time, options = {outputFormat: 'YYYY-MM-DD HH:mm', inputFormat: 'YYYY-MM-DD HH:mm:ss'}) {
    Vue.filter('toLocal', function (time, options = {outputFormat: 'MMMM DD, YYYY', inputFormat: 'DD-MM-YYYY HH:mm:ss'}) {
    var date = moment.utc(time, options.inputFormat)
    return date.isValid() ? moment(date).local().format(options.outputFormat) : ''
})

Vue.filter('toLocalNoti', function (time, options = {outputFormat: 'MMMM DD, YYYY', inputFormat: 'DD-MM-YYYY HH:mm:ss'}) {
    ////debugger
    //console.log(time)
    var newTime = ""
    if(time != "" && time != null){
        newTime = parseInt(time.split('/')[0]) + "/" + parseInt(time.split('/')[1])  + "/" + parseInt(time.split('/')[2])
    }
    //console.log(newTime)
    ////debugger
    var date = moment.utc(newTime, options.inputFormat)
    //date.setDate()
    //console.log(date)
    return date.isValid() ? moment(date).local().format(options.outputFormat) : ''
})

Vue.filter('extension', function (filename) {
    var date = filename.split('.')[1]
        return date
})

Vue.filter('unix', function (unix, outputFormat = 'YYYY-MM-DD HH:mm') {
    var date = moment.unix(unix).local()
    return date.isValid() ? date.format(outputFormat) : ''
})

Vue.filter('formatBytes', function (bytes, decimals = 2) {
    if (!bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
})

Vue.filter('fileType', function (mimeType) {
    switch (mimeType) {
        case 'image/jpeg':
            return 'far fa-file-image'
        case 'image/png':
            return 'far fa-file-image'
        case 'image/gif':
            return 'far fa-file-image'
        case 'application/msword':
            return 'far fa-file-word'
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'far fa-file-word'
        case 'application/vnd.ms-excel':
            return 'far fa-file-excel'
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'far fa-file-excel'
        case 'application/zip':
            return 'far fa-file-archive'
        case 'application/pdf':
            return 'far fa-file-pdf'
        default:
            return 'far fa-file'
    }
})

Vue.filter('productField', function (value, type) {
    switch (type) {
        case 'Text':
            return value.valueText ? value.valueText : ''
        case 'Number':
            return value.valueNumber ? value.valueNumber : ''
        case 'Date':
            var date = moment(value.valueDate ? value.valueDate : '')
            return date.isValid() ? date.format('YYYY-MM-DD') : ''
        case 'YesNo':
            return value.valueYesNo && value.valueYesNo == true ? 'Yes' : 'No'
        case 'TrueFalse':
            return value.valueTrueFalse && value.valueTrueFalse == true ? 'True' : 'False'
        case 'ListSingle':
            return value.valueListSingle ? value.valueListSingle : ''
        case 'ListMultiple':
            return value.valueListMultiple && _.isArray(value.valueListMultiple) ? value.valueListMultiple.join(' • ') : ''
        default:
            return ''
    }
})

Vue.filter('truncate', function (value, max = 35) {
    return value.substr(0,max-1)+(value.length>max?'..':''); 
})
