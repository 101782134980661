import decode from 'jwt-decode'
import generic from '../misc/generic'
import store from '../store'

const USER_TOKEN_KEY = 'USER_TOKEN'

export function getAccessToken() {
  const token = getToken()
  if (token) {
    const access_token = token.access_token
    const decodedAccessToken = decodeJwt(access_token)
    if (!decodedAccessToken.exp) { return undefined }
    const expiryDate = new Date(0)
    expiryDate.setUTCSeconds(decodedAccessToken.exp)

    if (!!access_token && expiryDate > (new Date())) {
      return access_token
    }
  }
  return undefined
}

export function getRefreshToken() {
  const token = getToken()
  if (token) {
    const refresh_token = token.refresh_token
    const decodedRefreshToken = decodeJwt(refresh_token)
    if (!decodedRefreshToken.exp) { return undefined }
    const expiryDate = new Date(0)
    expiryDate.setUTCSeconds(decodedRefreshToken.exp)

    if (!!refresh_token && expiryDate > (new Date())) {
      return refresh_token
    }
  }
  return undefined
}

export function setToken(token) {
  //debugger
  generic.setJsonLocalStorage(USER_TOKEN_KEY, token)
}

export function getToken() {
  return generic.getJsonLocalStorage(USER_TOKEN_KEY)
}

export function removeToken() {
  generic.removeLocalStorage(USER_TOKEN_KEY)
}

function decodeJwt(encoded) {
  try {
    return decode(encoded)
  } catch (e) {
    return {}
  }
}

export function getPermissions() {  
  const refresh_token = getRefreshToken()
  if (refresh_token) {
    const token = getToken()
    if (token) {
      const access_token = token.access_token
      const decodedAccessToken = decodeJwt(access_token)
      if (!decodedAccessToken.permission) { return undefined }
      const permissions = decodedAccessToken.permission
      if (!!access_token && permissions) {
        return permissions
      }
    }
  }
  // const access_token = getAccessToken()
  // if (access_token) {
  //   const decodedAccessToken = decodeJwt(access_token)
  //   if (!decodedAccessToken.permission) { return undefined }
  //   const permissions = decodedAccessToken.permission
  //   if (!!access_token && permissions) {
  //     return permissions
  //   }
  // }
  return undefined
}


// export function getUserType() {
//   return 'NOTISPHERE'
//   const refresh_token = getRefreshToken()
//   if (refresh_token) {
//     const decodedRefreshToken = decodeJwt(refresh_token)
//     if (!decodedRefreshToken.org_type) { return undefined }
//     const type = decodedRefreshToken.org_type
//     if (!!refresh_token && type) {
//       console.log('type=', type)
//       return 'NOTISPHERE'
//     }
//   }
//   return undefined
// }

// export function getOrgId() {
//   const access_token = getAccessToken()
//   if (access_token) {
//     const decodedAccessToken = decodeJwt(access_token)
//     if (!decodedAccessToken.org_id) { return undefined }
//     const org_id = decodedAccessToken.org_id
//     if (!!access_token && org_id) {
//       return org_id
//     }
//   }
//   return undefined
// }

const userPermissions = () => store.getters.permissions

export function hasAnyPermission(permissions) {
  //return true
  if (typeof permissions === 'string') {
    permissions = [permissions]
  }

  let userPermissionArr = userPermissions()
  if (!userPermissionArr) {
    return false
  }
  if (typeof userPermissionArr === 'string') {
    userPermissionArr = [userPermissionArr]
  }
  for (let i = 0; i < permissions.length; i++) {
    if (userPermissionArr.includes(permissions[i])) {
      return true
    }
  }

  return false
}
