import FileViewModel from '../FileViewModel'

export default {
    props: {
        type: {type: String, default: ''},
        value: {type: Object, default: {
            valueAcknowledge: null,
            valueText: null,
            valueNumeric: null,
            valueYesNo: null,
            valueCheckbox: null,
            valueImage: null,
            valueDocument: null
        }},
        recallId: {type: String, default: null},
        recallActionId: {type: Number, default: null},
        editMode: {type: Boolean, default: false},
        isSupplier: {type: Boolean, default: false}
    },
    components: {
        FileViewModel
    },
    computed: {
        _value: {
            get() {
                switch (this.type) {
                    case 'Acknowledge':
                        return (this.value && this.value.valueAcknowledge === true) ? true 
                        : ((this.value && this.value.valueAcknowledge === false) ? false : null)
                    case 'Text':
                        return this.value && this.value.valueText ? this.value.valueText : null
                    case 'Numeric':
                        return this.value && this.value.valueNumeric ? this.value.valueNumeric : null
                    case 'YesNo':
                        return (this.value && this.value.valueYesNo === true) ? true 
                        : ((this.value && this.value.valueYesNo === false) ? false : null)
                    case 'Checkbox':
                        return (this.value && this.value.valueCheckbox === true) ? true 
                        : ((this.value && this.value.valueCheckbox === false) ? false : null)
                }
                return null
            },
            set(val) {
                switch (this.type) {
                    case 'Text':
                        this.$emit('input', {valueText: val})
                        break;
                    case 'Numeric':
                        this.$emit('input', {valueNumeric: val})
                        break;
                    case 'YesNo':
                        this.$emit('input', {valueYesNo: val})
                        break;                        
                    case 'Checkbox':
                        this.$emit('input', {valueCheckbox: val})
                        break;
                }
            }
        },
    },
    methods:{
        isChange() {
            this.$emit('isChange', true)
        },
        async fileInput(files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                await this.uploadRecallActionFile(file)
            }
            this.getRecallActionFilesAsync()
        },
        // uploadRecallActionFile(file) {
        //     return new Promise((resolve, reject) => {
        //         let formData = new FormData()
        //         formData.append('actionType', this.type)
        //         formData.append('file', file)

        //         recallServices.uploadRecallActionFile(formData, this.recallId, this.recallActionId).then( resp => {
        //             if (resp.error) {
        //                 reject()
        //             } else {                        
        //                 resolve()
        //             }
        //         }).finally(()=>{
        //             reject()
        //         })
        //     })
        // },
        // getRecallActionFilesAsync() {
        //     recallServices.getRecallActionFilesAsync(this.recallId, this.recallActionId, this.type).then(resp=>{
        //         if (!resp.error) {
        //             if (this.type == 'Image'){
        //                 this.$emit('input', {valueImage: resp.data.d})
        //             }
        //             if (this.type == 'Document') {
        //                 this.$emit('input', {valueDocument: resp.data.d})
        //             }
        //         }
        //     })
        // },
        // deleteRecallActionFileAsync(fileId) {
        //     recallServices.deleteRecallActionFileAsync(this.recallId, this.recallActionId, fileId).then(resp=>{
        //         if (!resp.error) {
        //             this._showToast('Successfully Deleted!', {varient: 'success'})
        //             this.getRecallActionFilesAsync()
        //         }
        //     })
        // },
    }
}