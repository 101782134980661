import { faLeaf } from "@fortawesome/free-solid-svg-icons";

export default {
    props: {
        title: { type: String, default: 'Table Name' },
        btnLabel: { type: String, default: 'Add' },
        btnVerifyLabel: { type: String, default: 'Covered' },
        btnInvalidLabel: { type: String, default: 'Invalid' },
        btnCoveredLabel: { type: String, default: 'Not Covered' },
        btnIcon: { type: String, default: 'fas fa-plus' },
        showBtn: { type: Boolean, default: false },
        showDBtn: { type: Boolean, default: false },
        showCBtn: { type: Boolean, default: false },
        showVBtn: { type: Boolean, default: false },
        showIBtn: { type: Boolean, default: false },
        terms: { type: Boolean, default: false },
        //disabled: 1,
    },
    computed: {
        isDisabled: function(){
            ////debugger
          return !this.terms;
      }
    },
    methods: {
        isDisabled1: function(){
            if(this.terms)
            {
                    this.terms = false
            }
            else 
            {
                this.terms = true
            }
          return !this.terms;
      },
        btnClick(){
            this.$emit('btnClick')
        },
        btnDClick() {
            this.$emit('btnDClick')
        },
        btnVerify() {
            this.$emit('btnVerify')
        },
        btnInvalid() {
            this.$emit('btnInvalid')
        },
        btnCovered() {
            this.$emit('btnCovered')
        },
        btnDisbled() {
            ////debugger
           return !this.isDisabled1();
        }
    }
}