<template>
    <div class="paging" id="paging" :class="customClass">
        <ul id="page">
            <!-- <li v-if="first.link!='' &&  first.dataPage!=''" class="pc">
                <span>
                    <a v-bind:href="first.link" v-bind:data-page="first.dataPage" @click.prevent="loadContentAtPage(first.dataPage)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.68 8.109" style="width: 12px;">
                            <g id="Group_1730" data-name="Group 1730" transform="translate(-622.555 -1267.685)">
                                <g id="small-left" transform="translate(614.18 1258.31)">
                                    <path id="Path_24" data-name="Path 24" class="btnFirst" d="M16.43,16.859,13,13.43,16.43,10" transform="translate(0 0)"/>
                                </g>
                                <g id="small-left-2" data-name="small-left" transform="translate(610.18 1258.31)">
                                    <path id="Path_24-2" data-name="Path 24" class="btnFirst" d="M16.43,16.859,13,13.43,16.43,10" transform="translate(0 0)"/>
                                </g>
                            </g>
                        </svg>
                    </a>
                </span>
            </li> -->

            <li v-if="prev.link !== '' && prev.dataPage !== ''">
                <span>
                    <a v-bind:href="prev.link" v-bind:data-page="prev.dataPage" @click.prevent="loadContentAtPage(prev.dataPage)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.68 8.109" style="width: 7px;">
                            <path id="Path_24" data-name="Path 24" class="btnPrev" d="M16.43,16.859,13,13.43,16.43,10" transform="translate(-12.375 -9.375)"/>
                        </svg>
                    </a>
                </span>
            </li>
            
            <li v-if="edgeLeft.link !== '' && edgeLeft.dataPage !== ''" class="pc"><span><a v-bind:href="edgeLeft.link" v-bind:data-page="edgeLeft.dataPage" @click.prevent="loadContentAtPage(edgeLeft.dataPage)">{{ edgeLeft.dataPage }}</a></span></li>
            
            <li v-if="dotLeft" class="pc"><span><a href="javascript:void(0);" class="text">...</a></span></li>
            
            <li v-for="(item, index) in leftSide" v-bind:key="index"><span><a v-bind:href="item.link" v-bind:data-page="item.dataPage"  @click.prevent="loadContentAtPage(item.dataPage)">{{ item.dataPage }}</a></span></li>
            
            <li v-if="current.link !== '' && current.dataPage !== ''"><span><a class="active" href="javascript:void(0)" v-bind:data-page="current.dataPage">{{ current.dataPage }}</a></span></li>
            
            <li v-for="(item, index) in rightSide" v-bind:key="index"><span><a v-bind:href="item.link" v-bind:data-page="item.dataPage" @click.prevent="loadContentAtPage(item.dataPage)">{{ item.dataPage }}</a></span></li>
            
            <li v-if="dotRight" class="pc"><span><a href="javascript:void(0);" class="text">...</a></span></li>
            
            <li v-if="edgeRight.link !== '' && edgeRight.dataPage !== ''" class="pc"><span><a v-bind:href="edgeRight.link" v-bind:data-page="edgeRight.datpage" @click.prevent="loadContentAtPage(edgeRight.dataPage)">{{ edgeRight.dataPage }}</a></span></li>

            <li v-if="next.link !== '' && next.dataPage !== ''">
                <span>
                    <a v-bind:href="next.link" v-bind:data-page="next.dataPage" @click.prevent="loadContentAtPage(next.dataPage)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.68 8.109" style="width:7px;">
                            <g id="small-left" transform="translate(17.055 17.484) rotate(180)">
                                <path id="Path_24" data-name="Path 24" class="btnNext" d="M16.43,16.859,13,13.43,16.43,10" transform="translate(0 0)"/>
                            </g>
                        </svg>
                    </a>
                </span>
            </li>
            
            <!-- <li v-if="last.link !== '' && last.dataPage !== ''" class="pc">
                <span>
                    <a v-bind:href="last.link" v-bind:data-page="last.dataPage" @click.prevent="loadContentAtPage(last.dataPage)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.68 8.109" style="width:12px;">
                            <g id="Group_1731" data-name="Group 1731" transform="translate(-901.766 -1268.206)">
                                <g id="small-left" transform="translate(918.82 1285.69) rotate(180)">
                                    <path id="Path_24" data-name="Path 24" class="btnLast" d="M16.43,16.859,13,13.43,16.43,10" transform="translate(0 0)"/>
                                </g>
                                <g id="small-left-2" data-name="small-left" transform="translate(922.82 1285.69) rotate(180)">
                                    <path id="Path_24-2" data-name="Path 24" class="btnLast" d="M16.43,16.859,13,13.43,16.43,10" transform="translate(0 0)"/>
                                </g>
                            </g>
                        </svg>
                    </a>
                </span>
            </li> -->
        </ul>
    </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>

#paging {
    &.paging {
        //margin-top: 80px;
        text-align: center;
        background: #fff;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 15px 0;
        &.my-event-bet {
            margin-top: 15px !important;

            ul li {
                margin: 0;
            }
        }

        ul {
            padding: 0px;
            margin: 0px;

            li {
                display: inline-block;
                margin: 0px 10px;
                width: auto;
                border: 0;
                vertical-align: middle;

                a {
                    color: #009efb;
                    display: block;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    border-radius: 50%;
                    border: 1px solid #009efb;

                    &.active {
                        border-radius: 50%;
                        line-height: 30px;
                        background: #009efb;
                        color: #fff;

                        &:hover {
                            color: #fff;
                        }
                    }

                    &.text {
                        color: #fff;
                        cursor: default;
                        text-decoration: none;

                        &:hover {
                            color: #fff;
                        }
                    }

                    &.off {
                        color: #142C37;
                        cursor: default;
                    }

                    svg{
                        fill: none;
                        stroke: #009efb;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-miterlimit: 10;
                        stroke-width: 1.25px;
                    }

                    .btnNext {
                        fill: none;
                        stroke: #009efb;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-miterlimit: 10;
                        stroke-width: 1.25px;
                    }

                    .btnPrev {
                        fill: none;
                        stroke: #009efb;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-miterlimit: 10;
                        stroke-width: 1.25px;
                    }

                    .btnFirst {
                        fill: none;
                        stroke: #009efb;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-miterlimit: 10;
                        stroke-width: 1.25px;
                    }

                    .btnLast {
                        fill: none;
                        stroke: #009efb;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-miterlimit: 10;
                        stroke-width: 1.25px;
                    }

                    &:hover {
                        text-decoration: none;
                        color: #009efb;
                        svg,
                        .btnNext,
                        .btnPrev,
                        .btnFirst,
                        .btnLast {
                            stroke: #009efb;
                        }
                    }
                }
            }
        }
    }
}
    
</style>
<script>
export default {
    name: 'paging',
    props: {
        page: Number,
        total: Number,
        size: Number,
        pagerFull: Boolean,
        loadContentAtPage: Function,
        customClass: String
    },
    data() {
        return {
            min_page: 1,
            first: {
                link: '',
                dataPage: ''
            },
            prev: {
                link: '',
                dataPage: ''
            },
            next: {
                link: '',
                dataPage: ''
            },
            last: {
                link: '',
                dataPage: ''
            },
            current: {
                link: '',
                dataPage: ''
            },
            edgeLeft: {
                link: '',
                dataPage: ''
            },
            edgeRight: {
                link: '',
                dataPage: ''
            },
            leftSide: [],
            rightSide: [],
            dotLeft: false,
            dotRight: false,
        }
    },
    created() {
        this.page = Math.max(this.min_page, this.page)
        this.loadTemplate()
    },
    watch: {
        page: function (v) {
            this.resetData()
            this.loadTemplate()
        },
        total: function (v) {
            this.resetData()
            this.loadTemplate()
        }
    },
    methods: {
        loadTemplate: function () {
            if (this.total <= this.size) {
                return
            }

            //first page        
            if (this.page > this.min_page) {
                this.first.link = '?page=' + this.min_page
                this.first.dataPage = this.min_page
            }

            //prev page
            if (this.page - 1 >= this.min_page) {
                this.prev.link = '?page=' + (this.page - 1)
                this.prev.dataPage = this.page - 1
            }

            if (this.pagerFull) {
                //pageEdgeLeft
                if (this.page - 3 >= this.min_page) {
                    this.edgeLeft.link = '?page=' + this.min_page
                    this.edgeLeft.dataPage = this.min_page
                }

                //3dot left
                if (this.page - 3 > this.min_page) {
                    this.dotLeft = true
                }
            }

            //page left side
            for (var i = this.page - 2; i < this.page; i++) {
                if (i >= this.min_page) {
                    this.leftSide.push({
                        link: '?page=' + i,
                        dataPage: i
                    })
                }
            }

            //current page
            if (this.page >= this.min_page && this.page <= Math.ceil(this.total / this.size)) {
                this.current.link = '?page=' + this.page
                this.current.dataPage = this.page
            }

            //page right side
            for (var i = this.page + 1; i <= this.page + 2; i++) {
                if (i <= Math.ceil(this.total / this.size)) {
                    this.rightSide.push({
                        link: '?page=' + i,
                        dataPage: i
                    })
                }
            }

            if (this.pagerFull) {
                //3dot right
                if (this.page + 3 < Math.ceil(this.total / this.size)) {
                    this.dotRight = true
                }

                //pageEdgeLeft
                if (this.page + 3 <= Math.ceil(this.total / this.size)) {
                    this.edgeRight.link = '?page=' + Math.ceil(this.total / this.size)
                    this.edgeRight.dataPage = Math.ceil(this.total / this.size)
                }
            }

            //next page
            if (this.page < Math.ceil(this.total / this.size)) {
                this.next.link = '?page=' + (this.page + 1)
                this.next.dataPage = this.page + 1
            }

            //last page
            if (this.page < Math.ceil(this.total / this.size)) {
                this.last.link = '?page=' + Math.ceil(this.total / this.size)
                this.last.dataPage = Math.ceil(this.total / this.size)
            }
        },
        resetData: function () {
            this.first = {
                link: '',
                dataPage: ''
            }
            this.prev = {
                link: '',
                dataPage: ''
            }
            this.next = {
                link: '',
                dataPage: ''
            }
            this.last = {
                link: '',
                dataPage: ''
            }
            this.current = {
                link: '',
                dataPage: ''
            }
            this.edgeLeft = {
                link: '',
                dataPage: ''
            }
            this.edgeRight = {
                link: '',
                dataPage: ''
            }
            this.leftSide = []
            this.rightSide = []
            this.dotLeft = false
            this.dotRight = false
        }
    },
}
</script>
