//rules
import { extend } from 'vee-validate'
import { confirmed, required, email, min } from 'vee-validate/dist/rules'
import PhoneNumber from 'awesome-phonenumber'
import i18n from '@/i18n'

extend('phoneNumber', {
  message: () => i18n.t('Error.invalid_phone_num'),
  validate (value) {
    return new Promise(resolve => {
      let phone = new PhoneNumber(value)
      var strongRegex = new RegExp('^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$')
      //var strongRegex = new RegExp('^[-0-9a-zA-Z \/_?:.,\s]+$')
      resolve({ valid: strongRegex.test(value) })
    })
  }
})  

extend('verify_password', {
  message: () => 'Minimum 8 characters, must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (!@#$%^&*)',
  validate (value) {
      var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
      return strongRegex.test(value)
  }
})

extend('domain', {
  message: () => 'Invalid domain name',
  validate (value) {
      var strongRegex = new RegExp(/^[a-z0-9][a-z0-9-]{1,61}[a-z0-9](?:\.[a-z0-9]{2,})+$/g)
      
      return strongRegex.test(value)
      
  }
})


extend('confirmed', {
  ...confirmed,
  message: () => i18n.t('Error.password_not_match')
})
  
extend('required', {
  ...required,
  message: () => i18n.t('Error.field_required')
})
  
extend('requiredSelect', {
  message: () => i18n.t('Error.field_required'),
  validate (value) {
    return value
  }
})

extend('email', {
    ...email,
    message: () => i18n.t('Error.email_not_valid')
})

extend('min', {
  ...min,
  message: (val, args) => {
    return i18n.t('Error.min_char', {msg: args.length})
  }
})

extend('alphanumeric', {
  message: () => 'Only Alphanumeric and special characters like \\+/-_)( are accepted',
  validate (value) {
    var strongRegex = new RegExp(/^[0-9a-zA-Z/\\/+()_-]+$/)
      return strongRegex.test(value)
  }
})